import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import 'react-tooltip/dist/react-tooltip.css';
import RoleAuth from "./Components/RoleAuth";
import SidebarLayout from "./Components/SidebarLayout";
import Unauthorized from "./Components/Unauthorized";
import Admin from "./Pages/Admin/Admin";
import Business from "./Pages/Business/Business";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Devices from "./Pages/Devices/Devices";
import Login from "./Pages/Login/Login";
import NotificationComponent from "./Pages/Notification/Notification";
import Project from "./Pages/Project/Project";
import Report from "./Pages/Report/Report";
import Sensors from "./Pages/Sensors/Sensors";
import SignUp from "./Pages/SignUp/SignUp";
import configVariables from "./util/constants";
import Pay from "./Pages/Pay/Pay";
import SubscriptionDetails from "./Pages/SubscriptionDetails/SubscriptionDetails";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ForgotPassword/ResetPasswrod";
import Types from "./Pages/Admin/Types";
import InactiveAccount from "./Pages/Error/InactiveAccount";
import SubscriptionLayout from "./Components/Layouts/SubscriptionLayout";
import storageService from "./util/storageService";
import { SubscribeUser } from "./appActions";
import { WebSocketProvider } from "./WebSocketContext";
export const NotificationContext = React.createContext();

function App() {
  const [showNotificationPrompt, setShowNotificationPrompt] = useState(false);
  
  const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isPWA = () => window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

  useEffect(() => {
    const handleFocusIn = (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        document.body.style.zoom = '1';
      }
    };

    const handleFocusOut = (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        document.body.style.zoom = '';
      }
    };

    document.addEventListener('focusin', handleFocusIn);
    document.addEventListener('focusout', handleFocusOut);

    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
  };

  const subscribeUserToPush = async (registration) => {
    const vapidPublicKey = 'BB5OkXasNwkIWiXOZFBe0Efp_rY0DXr_oWF5_OAWhgAIY_c3cI67QDyuMA3P_CC_Q8hWcbGlTBVwUJYat1-CaLo'; 
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
    
    try {
      const existingSubscription = await registration.pushManager.getSubscription();
      const userId = storageService.get(configVariables.USER_ID);

      if (existingSubscription) {
        console.log('User is already subscribed.');
        return;
      }

      const newSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey,
      });

      console.log('New subscription:', newSubscription);
      await SubscribeUser(userId, newSubscription);
      console.log('Subscription successful');
    } catch (error) {
      console.error('Failed to subscribe user:', error);
    }
  };

  const requestNotificationPermission = async (registration) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        await subscribeUserToPush(registration);
      } else {
        console.log('Notification permission:', permission);
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };

  const initializeNotifications = async () => {
    const userId = storageService.get(configVariables.USER_ID); // Get the user ID from storage
    const registration = await navigator.serviceWorker.register('/service-worker.js');
    const existingSubscription = await registration.pushManager.getSubscription()

    if (isIOS() && isPWA() && userId && !existingSubscription) {

      setShowNotificationPrompt(true);
    }
  
    if ('serviceWorker' in navigator) {
      try {
        
        
        console.log('Service Worker registered with scope:', registration.scope);
  
        
  
        if (userId && !existingSubscription) { // Only proceed if userId exists
          if (Notification.permission === 'granted') {
            await subscribeUserToPush(registration);
          } else if (Notification.permission !== 'denied') {
            await requestNotificationPermission(registration);
          }
        } else {
          console.log('No user ID or existing subscription found, notification permission request will be skipped.');
        }
      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    }
  };
  const handlePostLogin = async () => {
    await initializeNotifications();
  };

  

  const handleIOSNotificationRequest = async () => {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        await requestNotificationPermission(registration);
        setShowNotificationPrompt(false);
      } catch (error) {
        console.error('Failed to setup notifications:', error);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    const initNotifications = async () => {
      if (isMounted) {
        await initializeNotifications();
      }
    };

    initNotifications();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="App">
      <NotificationContext.Provider value={{ handlePostLogin }}>
{showNotificationPrompt && (
  <div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    minWidth: '300px',
    maxWidth: '400px',
    height:'163px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#dddddd',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    gap: '12px'
  }}>
    <h2 style={{
      fontSize: '17px',
      fontWeight: '600',
      margin: 0
    }}>
      “Notifications”
    </h2>
    <p style={{
      fontSize: '14px',
      color: 'black',
      margin: 0,
      padding: '0 10px',
      fontWeight: '600',
    }}>
      Only the ‘Allow’ option will enable in-app notifications. Please make sure to enable it.
    </p>
    <div style={{
      display: 'flex',
      width: '100%',
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      marginTop: '10px',
      
    }}>
      <button
        onClick={() => setShowNotificationPrompt(false)}
        style={{
          flex: 1,
          padding: '12px 0',
          backgroundColor: 'transparent',
          color: '#007AFF',
          border: 'none',
          borderRight: '1px solid rgba(0, 0, 0, 0.1)', 
          fontSize: '15px',
          fontWeight: '600',
          cursor: 'pointer'
        }}
      >
        Deny
        </button>
      <button
        onClick={handleIOSNotificationRequest}

        style={{
          flex: 1,
          padding: '12px 0',
          backgroundColor: 'transparent',
          color: '#007AFF',
          border: 'none',
          fontSize: '15px',
          fontWeight: '600',
          cursor: 'pointer'
        }}
      >
        Allow
      </button>
    </div>
  </div>
)}


<WebSocketProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<SubscriptionLayout />}>
            <Route element={<SidebarLayout />}>
              <Route element={<RoleAuth allowedRoles={[configVariables.role_owner]} />}>
                <Route path="/manage/business" element={<Business />} />
              </Route>

              <Route element={<RoleAuth allowedRoles={[configVariables.role_owner, configVariables.role_manager]} />}>
                <Route path="/manage/project" element={<Project />} />
              </Route>

              <Route element={<RoleAuth allowedRoles={[configVariables.role_owner, configVariables.role_manager, configVariables.role_super]} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/manage/devices" element={<Devices />} />
                <Route path="/manage/devices/sensors/:id" element={<Sensors />} />
                <Route path="/report" element={<Report />} />
                <Route path="/notification" element={<NotificationComponent />} />
              </Route>

              <Route element={<RoleAuth allowedRoles={[configVariables.role_admin]} />}>
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/types" element={<Types />} />
              </Route>

              <Route path="/subscription_details" element={<SubscriptionDetails />} />
            </Route>

            <Route path="/account_inactive" element={<InactiveAccount />} />
          </Route>

          <Route path="/signup/:secret_key" element={<SignUp />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login onLoginSuccess={handlePostLogin}/>} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route element={<RoleAuth allowedRoles={[configVariables.role_owner, configVariables.role_admin]} />}>
            <Route path="/pay" element={<Pay />} />
            <Route path="/renew/pay" element={<Pay showLogOut />} />
          </Route>
        </Routes>
      </BrowserRouter>
      </WebSocketProvider>
      </NotificationContext.Provider>
    </div>
  );
}

export default App;
