import React, { useEffect, useState ,useContext} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FetchGraphData } from '../appActions';
import configVariables, { graphTypes } from '../util/constants';
import LineChart from './LineChart'
import Map from './Map';
import edit_logo from '../Assets/Icons/edit.png'
import trash_logo from '../Assets/Icons/trash.png'
import share_logo from '../Assets/Icons/share.png'
import Alert from './Alert';
import SingleValue from '../SingleValue';
import BarChart from './BarChart';
import SwitchChart from './SwitchChart';
import BatteryChart from './BatteryChart';
import LevelChart from './LevelChart';
import CircularProgressChart from './CircularProgressChart';
import SliderChart from './SliderChart';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import FlightIndicators from './FlightIndicators';
import FlightGps from './FlightGps';
import FlightMessage from './FlightMessage';
import FlightMap from './FlightMap';
import SendMessageComponent from './RtlButton';
import Tablechart from './Table';
import FlightmapSecond from './FlightMapSecond';
import {publishData} from './../appActions.js';
import { WebSocketContext } from '../WebSocketContext.js';




function Chart({ type, name, data_query, setEditGraphModeal, graph_det, deleteGraph, setShareGraphModal, report_graph }) {
  const state = useSelector((state) => state);
  const [data, setData] = useState([])
  const { message,isWebSocketActive  } = useContext(WebSocketContext);
  
 
  useEffect(() => {
    if (!report_graph&&message && message.topic) {
      if (message.topic === graph_det.topic) {
        fetchData();
      }
    }
    fetchData();
  }, [message]); 

  useEffect(() => {
    fetchData()
    let intervalId;
    if (!report_graph && !isWebSocketActive) {
      console.log('fetching using interval ')
      if(data_query.topic[0]==='i'){
         intervalId = setInterval(fetchData, graph_det.sensor_id.fetch_interval*1000 );

      }
      else{
         intervalId = setInterval(fetchData,  1000);

      }
      return () => clearInterval(intervalId)
    }
  }, [isWebSocketActive])

  const fetchData = async () => {
   
    let whole_data = []

    const data_res = await FetchGraphData({
      metaquery: data_query,
      
      limitquery: data_query.limit,
      timequery: data_query.timequery
    })

    whole_data.push(data_res)
    if (data_query.merged_graphs?.length > 0) {
      await Promise.all(data_query.merged_graphs.map(async query => {
        let data = await FetchGraphData({
          metaquery: query,
          limitquery: query.limit
        })
        whole_data.push(data)
      }))
    }
    
    
    if (data !== whole_data) setData(whole_data)


      const parseCustomDate = (dateString) => {
        const [time, date] = dateString.split(' ');
        const [day, month, year] = date.split('/');
        // Format day and month to ensure two digits
        const formattedDay = day.padStart(2, '0');
        const formattedMonth = month.padStart(2, '0');
        const formattedDateString = `${year}-${formattedMonth}-${formattedDay}T${time}`;
        return new Date(formattedDateString);
      };
      
      // Example usage:
      const lastData = whole_data[0];
      if (lastData && lastData[0].datetime) {
        const lastDataTime = parseCustomDate(lastData[0].datetime).getTime();
        const currentTime = new Date().getTime();
        const connection_status=graph_det.device_id.connection_status
        
        // Calculate the time difference allowed (5 times the fetch interval)
        const fetchIntervalMs = graph_det.sensor_id.fetch_interval * 1000; // fetch_interval in milliseconds
        const maxAllowedTimeDifference = fetchIntervalMs * 5;  // 5 times the fetch interval
        
        const actualTimeDifference = Math.abs(currentTime - lastDataTime);
        
        // Check if the last saved time has passed the 5x fetch interval
        if (connection_status && actualTimeDifference > maxAllowedTimeDifference) {
          
          const data = {
            data:{topic:graph_det.device_id.topic },
            topic: `ah/d_con/${graph_det.device_id.topic}`
          };
          publishData(data)
          console.log("Last data is older than the allowed time difference (5x fetch interval).");
          
        } 
        // Check if the last saved time has passed the 5x fetch interval
        else if (!connection_status && actualTimeDifference < maxAllowedTimeDifference) {
          
          const data = {
            data:{topic:graph_det.device_id.topic },
            topic: `ah/con/${graph_det.device_id.topic}`
          };
          publishData(data)
          console.log("chnaged to online");
        }
      }
      
  }
  



  return (
    <div className={`chart-box ${type}-chart-box`} style={{ height: `calc(${graphTypes[type]?.height || '14em'} - 20px)`   }}>
      {!report_graph &&
        <div className="proj-card-opts graph-opts">
          <p className='graph-title'>{name}</p>
          <div className='graph-opts-wrapper'>
            <div className="opt-box ptr">
              {/* <img src={edit_logo} onClick={() => { setEditGraphModeal(graph_det) }} alt="" /> */}
              <EditIcon onClick={() => { setEditGraphModeal(graph_det) }} style={{ cursor: 'pointer' ,color: 'white'}}/>
            </div>
            <div className="opt-box ptr">
              {/* <img src={trash_logo} onClick={() => { deleteGraph(graph_det._id) }} alt="" /> */}
              <DeleteIcon onClick={() => { deleteGraph(graph_det._id) }} style={{ cursor: 'pointer' ,color: 'white'}}/>
            </div>
            <div className="opt-box ptr">
              {/* <img src={share_logo} onClick={() => { setShareGraphModal({ business_id: graph_det.business_id, graph_id: graph_det._id }) }} alt="" /> */}
              <ShareIcon onClick={() => { setShareGraphModal({ business_id: graph_det.business_id, graph_id: graph_det._id }) }} style={{ cursor: 'pointer' ,color: 'white'}}/>
            </div>
          </div>
        </div>
      }
      {}
     
      {type === 'line' && <LineChart data={data} graph_det={graph_det || {}} />}
      {type === 'gps' && <Map data={data[0]} />}
      {type === 'flight_Indicator' && <FlightIndicators data={data[0] } />}
      {type === 'flightgps' && <FlightGps data={data[0]} />}
     {type === 'FlightmapSecond' &&  <FlightmapSecond   data1={data[0]} topic={data_query.topic} />}
      {type === 'flightmap' && <FlightMap data={data[0]} />} 
      {type === 'flightmessage' && <FlightMessage data={data[0]} />}
      {type === 'alert' && <Alert data={data[0]} />}
      {type === 'fixed' && <SingleValue data={data[0]} no_divs={1} unit={graph_det.sensor_id?.type?.unit_symbol} />}
      {type === 'bar' && <BarChart data={data[0]} graph_det={graph_det} />}
      {type === 'switch' && <SwitchChart data={data[0]} graphDet={data_query} />}
      {type === 'battery' && <BatteryChart data={data[0]} />}
      {type === 'level' && <LevelChart data={data[0]} layout={'vertical'} unit={graph_det.sensor_id?.type?.unit_symbol} />}
      {type === 'progress' && <CircularProgressChart data={data[0]} />}
      {type === 'horizontal_level' && <LevelChart data={data[0]} layout={'horizontal'} unit={graph_det.sensor_id?.type?.unit_symbol} />}
      {type === 'network_meter' && <SingleValue data={data[0]} no_divs={30} unit={graph_det.sensor_id?.type?.unit_symbol} />}
      {type === 'slider' && <SliderChart data={data[0]} graphDet={data_query} unit={graph_det.sensor_id?.type?.unit_symbol} />}
      {type === 'Table' && <Tablechart data={data[0]}/>}
      {type === 'ARM_RTL' && <SendMessageComponent  topic={data_query.topic} data={data[0]}/>}
    </div>
  )
}



export default Chart