import React, { createContext, useState, useEffect, useRef } from "react";

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const [isWebSocketActive, setIsWebSocketActive] = useState(false); // Track WebSocket status
  const WS_URL = "wss://cloud.abhram.com:8080"; 
  const wsRef = useRef(null);
  const reconnectAttempts = useRef(0); // Track reconnect attempts
  const maxReconnectInterval = 30000; // Max wait time: 30s

  const connectWebSocket = () => {
    wsRef.current = new WebSocket(WS_URL);

    wsRef.current.onopen = () => {
      console.log("WebSocket Connected");
      setIsWebSocketActive(true);
      reconnectAttempts.current = 0; // Reset counter on successful connection
    };

    wsRef.current.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        setMessage(message);
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);

      }
    };

    wsRef.current.onerror = (error) => {
      console.error("WebSocket Error:", error);
      setIsWebSocketActive(false);
    };

    wsRef.current.onclose = (event) => {
      console.warn(`WebSocket Disconnected: Code ${event.code}, Reason: ${event.reason}`);
      scheduleReconnect();
      setIsWebSocketActive(false); 
    };
  };

  const scheduleReconnect = () => {
    const reconnectDelay = Math.min(5000 * Math.pow(2, reconnectAttempts.current), maxReconnectInterval);
    console.log(`Attempting to reconnect in ${reconnectDelay / 1000} seconds...`);
    
    setTimeout(() => {
      reconnectAttempts.current += 1;
      connectWebSocket();
    }, reconnectDelay);
  };

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (wsRef.current) wsRef.current.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ message, isWebSocketActive }}>
      {children}
    </WebSocketContext.Provider>
  );
};
