import React, { useEffect, useState, useContext } from 'react';
import Notifications from 'react-notifications-menu';
import noti_logo from '../../Assets/Icons/noti_white.png';
import './Notification.css';
import callApi from '../../util/callApi';
import call_logo from '../../Assets/Icons/call-chat-svgrepo-com.svg';
import mail_logo from '../../Assets/Icons/mail-plus-svgrepo-com.svg';
import smslogo from '../../Assets/Icons/message-square-lines-alt-svgrepo-com (1).svg';
import { WebSocketContext } from './../../WebSocketContext.js';
import storageService from './../../util/storageService.js';
import configVariables from './../../util/constants.js';

const icons = {
  call: call_logo,
  sms: smslogo,
  email: mail_logo,
};

function Notification() {
  const [data, setData] = useState([]); // Store notifications
  const { message } = useContext(WebSocketContext);

  useEffect(() => {
    fetchAlerts();
    const userId = storageService.get(configVariables.USER_ID);
    if (message?.topic === userId) {
      fetchAlerts();
    }
    
  }, [message]); // Only trigger when message changes

  const markAllAsRead = async () => {
    try {
      await callApi('mark_all_as_read', 'post');
      setData([]); // Clear notifications after marking them as read
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };
  
  const fetchAlerts = async () => {
    try {
      const alerts = await callApi('fetch_alerts', 'get');
   
      // Check if alerts exist before updating state
      if (alerts?.data?.length > 0) {
        const formattedAlerts = alerts.data.map(alert => ({
          key: `${alert._id}-${alert.time}`,
          image: icons[alert.type],
          message: `${alert.message} - ${new Date(alert.time).toLocaleString()}`,
        }));
        
      
        setData([]);
      setTimeout(() => setData(formattedAlerts), 10);
      }
    } catch (error) {
      console.error('Error fetching alerts:', error);
    }
  };

  return (
    <Notifications
      data={data}
      header={{
        title: 'Notifications',
        option: { text: 'Mark All As Read', onClick:markAllAsRead  },
      }}
      icon={noti_logo}
      renderNotification={(notification) => (
        <div
          key={notification.key}
          dangerouslySetInnerHTML={{ __html: notification.message }}
          style={{ whiteSpace: 'pre-wrap' }}
        />
      )}
    />
  );
}

export default Notification;
